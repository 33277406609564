@import '../../scss/variables';

.plus__wrapper {
  position: relative;
}

.toolbar .plus {
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  width: $spacing--big * 2;
  height: $spacing--big * 2;
  border-radius: 50%;
  background-color: $color--secondary--normal;
  transition: background-color 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbar .plus svg * {
  stroke: #fff;
}

.toolbar .plus:hover {
  background-color: darken($color--secondary--normal, 20);
}

.toolbar .plus:hover svg * {
  stroke: #fff;
}

.toolbar .plus.active {
  background-color: $color--primary--darkest;
}

.toolbar .plus.active svg * {
  stroke: #fff;
}

.plus__container {
  color: #fff;
  font-size: $text--normal;
  position: absolute;
  left: ($spacing--big * 2) + $spacing--small;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: $space--normal;
  background: $color--primary--normal;
  z-index: 9999999;
  border-radius: $border--radius;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;
    border-color:  transparent  $color--primary--normal transparent transparent;
    position: absolute;
    left: -12px;
    transform: translateY(-50%);
    top: 50%;
  }
}

.plus__container button {
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  font-size: $text--normal;
  transition: color 0.25s;
  height: $space--big;
  width: 100%;
}

.plus__container button svg * {
  transition: stroke 0.25s;
  stroke: #fff;
}

.plus__container button svg {
  margin-right: $space--small;
  display: none;
}

.plus__container button:hover {
  color: $color--primary--darkest;
}

.plus__container button:hover svg * {
  stroke: $toolbar-color-primary;
}
