@import '../../scss/variables';

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing--small 0;
  padding: 0;
}

.section-title h2 {
  color: $color--text--hilighted;
  font-size: $font-size--bigger;
  font-weight: $font--bold;
  margin: 0;
  padding: 0;
}
