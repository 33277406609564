@import '../../scss/variables';

.field {
  margin-bottom: $space--big;
}

.field__label {
  font-size: $text--normal;
  font-weight: $font--normal;
  color: $color--text--labels;
  margin-bottom: $unit;
  display: block;
}

.field__error {
  font-size: $text--normal;
  font-weight: $font--normal;
  color: $color--error;
  margin-top: $unit;
}

.field_input__error {
  border-color: $color--error;
}

.non-required {
  font-size: $text--small;
  font-weight: $font--normal;
  color: lighten($color--text--labels, 20);
  margin-left: $unit / 2;
}

.field__group {
  display: flex;

  .field:not(:last-child) {
    margin-right: $space--big;
  }

  .field {
    flex-grow: 1;
  }

  .time-day {
    flex-basis: 10rem;
  }
}

.buttons-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space--big;

  button {
    margin-bottom: $space--small;
    outline: none;
    border: 1px solid transparent;

    &:focus,
    &:active {
      border-color: $color--secondary;
      box-shadow: 0 0 8px 0 rgba(0, 188, 139, 0.1);
    }
  }

  button:not(.active) {
    background-color: adjust-color($color--neutral, $lightness: 60);
  }

  button:not(:last-child) {
    margin-right: $space--small;
  }
}
