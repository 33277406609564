@import '../../../scss/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(50, 62, 88, 0.7);
  z-index: 999990;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999991;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modal {
  z-index: 999992;
  background: white;
  position: relative;
  border-radius: 3px;
  max-width: 20rem;
  min-width: 10rem;
  max-height: 100vh;
  width: 100%;
  padding: 0;
  box-shadow: 0 0.25rem 0.5rem rgba(255, 255, 255, 0.2);
  //overflow: scroll;
  margin-top: $space;
}

.modal--large {
  max-width: 40rem;
}

.modal--normal {
  max-width: 24em;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  appearance: none;
  outline: 0;
  background-color: transparent;
  border: 0;
  padding: $space--normal;
  cursor: pointer;
  box-sizing: border-box;
}

.modal-close-button svg * {
  transition: stroke 0.25s;
  stroke: #ccc;
}

.modal-close-button:hover svg * {
  stroke: #a38cff;
}

.modal-header-title {
  font-size: $text--bigest;
  font-weight: $font--bold;
  padding: $space--big;
  color: $color--text--dark;
}

.modal-content {
  padding: 0 $space--big $space--big;
  //overflow: scroll;
}
