@import '../../scss/variables';

.tabs {
  display: flex;
}

.tabs_tab {
  display: block;
  margin-right: $spacing--biggest;
  text-decoration: none;
  color: $color--text--normal;
  padding: $spacing--smallest 0 $spacing--small 0;
}

.tabs_tab--active {
  color: $color--text--normal;
  border-bottom: 1px solid $color--terciary--normal;
}
