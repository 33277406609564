@import '../../scss/variables';

.part {
  margin-right: $spacing--biggest;
  position: relative;

  .part-title {
    margin-bottom: $spacing--small;
    color: $color--text--label;
    font-size: $spacing--normal;
  }

  .part-content {
    color: $color--text--normal;
    font-size: $spacing--big;
    font-weight: $font--bold;
  }

  .part-badge {
    display: inline-block;
    margin-right: $space--small;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333;
  }
}
.part:last-child {
  margin-right: 0;
}

.part--with-donut {
  padding-left: 64px;
  height: 48px;

  .part-donut {
    position: absolute;
    top: 0;
    left: 0;

    .donutchart-track {
      stroke: hsla(0, 0, 0, 0.1);
    }
  }
}

.dont-chart-economy {
  .part-donut {
    .donutchart-indicator {
      stroke: rgb(252, 1, 1);
    }
    .donutchart-track {
      stroke: rgb(6, 193, 24);
    }
  }
}
