@import '../../scss/variables';

.row__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: $spacing--smallest;
  right: $spacing--smallest;
  bottom: $spacing--smallest;
  background-color: mix($color--background, $color--secondary--normal, 95);
  visibility: hidden;
  border-radius: $border--radius;
  transform: translateX(100%);
  transition: all 100ms ease-in-out;
  transition-delay: 300ms;
}

.row__actions button {
  appearance: none;
  outline: 0;
  border: 0;
  background-color: transparent;
  color: $color--secondary--normal;
  padding: $spacing--small;
  margin-left: $spacing--smallest;
  border-radius: $border--radius;
  display: flex;
  justify-content: center;
  align-items: stretch;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.row__actions button svg {
  stroke: $color--secondary--normal;
}

.row__actions button:hover,
.row__actions button:focus,
.row__actions button:active {
  background-color: mix($color--background, $color--secondary--normal, 70);
}

.row__actions button:hover  svg,
.row__actions button:focus  svg,
.row__actions button:active  svg {
  stroke: mix(#000, $color--secondary--normal, 50);
}

.row__actions button.button--danger svg {
  stroke: $color--danger;
}
