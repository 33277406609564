@import '../../../scss/variables';

.button {
  appearance: none;
  outline: 0;
  border: 0;
  background-color: mix($color--background, $color--secondary--normal, 95);
  color: $color--secondary--normal;
  font-weight: $font--bold;
  font-size: $font-size--normal;
  line-height: $spacing--big * 2;
  padding: 0 $spacing--big;
  border-radius: $border--radius;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
}

.button svg {
  margin-right: $spacing--small;
  stroke: $color--secondary--normal;
}

.button:hover,
.button:focus,
.button:active {
  background-color: mix($color--background, $color--secondary--normal, 70);
  color: mix(#000, $color--secondary--normal, 50);
}

.button:hover svg,
.button:focus svg,
.button:active svg {
  stroke: mix(#000, $color--secondary--normal, 50);
}

.button--secondary {
  background-color: $color--secondary--normal;
}

.button--full {
  width: 100%;
  justify-content: center;
}

.button--bigger {
  line-height: $spacing--big * 3;
  padding: 0 ($spacing--big * 2);
  font-size: $font-size--big;
}

.button--reset {
  width: $spacing--bigger;
  height: $spacing--bigger;
  background-color: $color--primary--light;
  appearance: none;
  outline: 0;
  border: 0;
  position:absolute;
  top: $spacing--small;
  right: -0.85rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button--reset svg {
  stroke: #fff;
}

.button--reset:hover {
  background-color: mix(#000, $color--primary--light, 20);
}
