@import '../../scss/variables';

.project__thumbnail {
  padding: $space--small $space--normal;
  border-radius: $border--radius;
  border: $border;
  height: 6rem;
  display: flex;
  flex-direction: column;
  transition: background-color 0.25s, transform 0.15s;
  color: $color--text--normal;
  font-size: $font-size--normal;
  background-color: #fff;

  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    border-color: darken($color--card--border, 5%);
    transform: scale(1.02) translateY(-0.25rem);
    box-shadow: $shadow;
  }
}

.project__thumbnail__top,
.project__thumbnail__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project__thumbnail__top {
  margin-bottom: $space--small;
}

.project__thumbnail__bottom {
  margin-top: $space--small;
}

.project__thumbnail__number {
  svg {
    margin-right: $unit;
    color: green;
  }
}

.project__thumbnail__name {
  color: $color--text--hilighted;
  font-size: $font-size--big;
  font-weight: $font--bold;
  margin: auto 0;
  overflow: hidden;
}

.project__thumbnail__customer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: $space--small;
}

