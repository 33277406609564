@import '../../scss/variables';

section {
  border-bottom: $border--divider;
  padding: $block--padding;
  position: relative;
}

.section--no_border {
  border-bottom: 0;
}

.section--filters {
  padding-top: 0;
}

.section--tabs {
  border-bottom: 0;
  padding-bottom: 0;
}

.section--centered {
  text-align: center;
}

.section--centered-no-border {
  border-bottom: 0;
  text-align: center;
}
