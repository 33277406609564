@import '../../scss/variables';

.customer__thumbnail {
  //border: 1px solid $color--card--border;
  padding: $space--small $space--normal;
  border: $border;
  background-color: #fff;
  border-radius: $border--radius;
  height: 4rem;
  display: flex;
  flex-direction: column;
  transition: background-color 0.25s, transform 0.15s;
  color: $color--text--normal;
  font-size: $font-size--normal;

  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    border-color: darken($color--gray-light, 5%);
    transform: scale(1.02) translateY(-0.25rem);
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.03);
  }
}

.customer__thumbnail__name {
  color: $color--text--hilighted;
  font-size: $font-size--big;
  font-weight: $font--bold;
  height: 2rem;
  overflow: hidden;
  margin: auto 0;
}

.customer__thumbnail__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
