@import '../../../scss/variables';

/* SPINNER */
.spinner-padding {
  padding: 1rem;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border-width: 0.25rem;
  border-style: solid;
  border-color: $color--primary--normal transparent transparent transparent;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

.spinner.button {
  width: 1rem;
  height: 1rem;
}

.spinner.button div {
  width: 1rem;
  height: 1rem;
  border-width: 0.25rem;
  border-color: #fff transparent transparent transparent;
}

.spinner.white div {
  border-color: #fff transparent transparent transparent;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner--fade-enter {
  opacity: 0;
}
.spinner--fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.spinner--fade-exit {
  opacity: 1;
}
.spinner--fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
