@import '../../scss/variables';

$state-font-size: $font-size--smallest;
$state-font-weight: $font--bold;

.state {
  appearance: none;
  outline: 0;
  border: 0;
  position: relative;
  background: transparent;
  line-height: $spacing--biggest;
  height: $spacing--biggest;
  padding: 0;
  border-radius: $spacing--biggest / 2;
  text-transform: uppercase;
  font-size: $state-font-size;
  font-weight: $state-font-weight;
  color: $color--primary--normal;
}

.state--0 {
  color: #bac8ff;
  border-color: #bac8ff;
}

.state--1 {
  color: #99e9f2;
  border-color: #99e9f2;
}

.state--2 {
  color: #b2f2bb;
  border-color: #b2f2bb;
}

.state--3 {
  color: #ffd8a8;
  border-color: #ffd8a8;
}

.state--4 {
  color: #eebefa;
  border-color: #eebefa;
}

.state--5 {
  color: #dee2e6;
  border-color: #dee2e6;
}
