@import '../../scss/variables';
@import '../../scss/mixins';

.filters__section--fulltext {
  @include grid;
}

.state-filter {
  appearance: none;
  outline: 0;
  border: $border;
  position: relative;
  background: transparent;
  line-height: $spacing--biggest;
  padding: 0 $spacing--normal;
  border-radius: $spacing--biggest / 2;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: $font--bold;
  color: $color--text--normal;
  margin-right: $spacing--small;
  cursor: pointer;
}

.state-filter.active {
  border-color: $color--primary--normal;
  color: $color--primary--normal;;
}

.state-filter--0.active {
  color: #bac8ff;
  border-color: #bac8ff;
}

.state-filter--1.active {
  color: #99e9f2;
  border-color: #99e9f2;
}

.state-filter--2.active {
  color: #b2f2bb;
  border-color: #b2f2bb;
}

.state-filter--3.active {
  color: #ffd8a8;
  border-color: #ffd8a8;
}

.state-filter--4.active {
  color: #eebefa;
  border-color: #eebefa;
}

.state-filter--5.active {
  color: #dee2e6;
  border-color: #dee2e6;
}

.state-filter__badge {
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  display: block;
  height: 1rem;
  min-width: 0.5rem;
  border-radius: 0.75rem;
  background-color: $color--primary--dark;
  color: #fff;
  line-height: 1rem;
  padding: 0 0.25rem;
  font-weight: $font--bold;
}

.filters__section--state {
  margin-top: $spacing--big;
}
