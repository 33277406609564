// Colors.
$color_primary_hue: 220;
$color_primary_saturation: 20;
$color_primary_lightness: 20;

$color--primary: hsl($color_primary_hue, $color_primary_saturation, $color_primary_lightness);
$color--primary--lightest: hsl($color_primary_hue, $color_primary_saturation, 96);
$color--text: hsl($color_primary_hue, $color_primary_saturation, 10);
$color--gray-lightest: hsl($color_primary_hue, $color_primary_saturation, 90);
$color--gray-light: hsl($color_primary_hue, $color_primary_saturation, 80);
$color--gray: hsl($color_primary_hue, $color_primary_saturation, 50);
$color--gray-dark: hsl($color_primary_hue, $color_primary_saturation, 20);
$color--gray-darkest: hsl($color_primary_hue, $color_primary_saturation, 10);
$color--danger: #ed0d2a;
$color--white: #fff;
$color--divider: hsl($color_primary_hue, $color_primary_saturation, 96);
$color--card--border: hsl($color_primary_hue, $color_primary_saturation, 90);
$color--secondary: #03BC8B;

$color--input-border-hover: #8f858c;
$color--input-border-focus: $color--primary;

$color--labels: hsl($color_primary_hue, $color_primary_saturation, 80);

// Fonts.
$font--family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

// Colors.
$color--primary: hsl(140, 60, 60);
$color--secondary: hsl(220, 100, 50);
$color--alert: hsl(0, 100, 40);
$color--error: red;
$color--warning: hsl(40, 100, 50);
$color--neutral: hsl(220, 30, 30);

$color--text--light: adjust-color($color--neutral, $lightness: 66);
$color--text--dark: adjust-color($color--neutral, $lightness: -20, $saturation: -10);
$color--text--labels: adjust-color($color--neutral, $lightness: 10, $saturation: -20);

$color--border: adjust-color($color--neutral, $saturation: -10, $lightness: 60);
$color--border--input: adjust-color($color--neutral, $saturation: -10, $lightness: 50);

$color--block-top: adjust-color($color--neutral, $saturation: -10, $lightness: 56);
$color--block-title: adjust-color($color--neutral, $saturation: -10, $lightness: 60);
$color--block-info: adjust-color($color--neutral, $saturation: -10, $lightness: 64);

$color--block-top--header: adjust-color($color--neutral, $lightness: -24);
$color--block-title--header: adjust-color($color--neutral, $lightness: -10);
$color--block-info--header: $color--neutral;

$color--button--primary: $color--primary;
$color--button--secondary: $color--secondary;

$color--graph--donut--background: adjust-color($color--neutral, $saturation: 20, $lightness: 80);


// Design unit.
$unit: 0.5rem;
$border--radius: 9px;

// Spacing.
$space: $unit * 2;
$space--small: $unit;
$space--normal: $unit * 1.5;
$space--big: $unit * 3;

// Text.
$text--mini: 0.55rem;
$text--small: 0.625rem;
$text--normal: 0.75rem;
$text--bigger: 0.875rem;
$text--big: 1rem;
$text--bigest: 1.125rem;
$text--huge: 1.25rem;

$text--bold: 900;
$text--medium: 700;
$text--regular: 500;

// Body.
$body--background: hsl(200, 70, 98);

// Block
$block--background: $color--white;
$block--border_top_color: #D5D7DD;
$block--border_top_color--header: #454A54;
/*$block--border_top_color--dates: #F2E1D9;
$block--border_top_color--plans: #EEF2D9;
$block--border_top_color--offer: #E1D9F2;
$block--border_top_color--realities: #F2D9E5;
$block--border_top_color--times: #D9EAF2;
$block--border_top_color--invoices: #F2EAD9;
$block--border_top_color--notes: #D9F2F2;
 */
$block--border_top_color--dates: mix(#fff, $color--primary, 80);
$block--border_top_color--plans: mix(#fff, $color--primary, 80);
$block--border_top_color--offer: mix(#fff, $color--primary, 80);
$block--border_top_color--realities: mix(#fff, $color--primary, 80);
$block--border_top_color--times: mix(#fff, $color--primary, 80);
$block--border_top_color--invoices: mix(#fff, $color--primary, 80);
$block--border_top_color--notes: mix(#fff, $color--primary, 80);

// Divider.
$divider: 1px solid $color--divider;

// Row.
$row--background-color: $color--primary--lightest;

// Toolbar.
$toolbar-color-background: #fff;
$toolbar-color-primary: #03BC8B;
$toolbar-color-primary-hover: mix(#000, $toolbar-color-primary, 30);
$toolbar-height: 3rem;


$color--primary--lightest: hsl(210, 50, 90);
$color--primary--light: hsl(210, 50, 80);
$color--primary--normal: hsl(210, 50, 60);
$color--primary--dark: hsl(210, 50, 40);
$color--primary--darkest: hsl(210, 50, 20);

$color--secondary--normal: hsl(223, 77, 60);
$color--terciary--normal: hsl(15, 74, 53);

$color--background: #FCFDFF;


$color--text--label: hsl(210, 20, 60);
$color--text--normal: hsl(210, 20, 20);
$color--text--hilighted: hsl(210, 20, 20);

$color--neutral--normal: hsl(0, 0, 96);

$color--alert: hsl(0, 100, 40);
$color--error: red;
$color--warning: hsl(40, 100, 50);
$color--danger: red;

$color--borders: $color--neutral--normal;

$spacing--thin: 0.125rem;
$spacing--smallest: 0.25rem; // 4px
$spacing--small: 0.5rem; // 8px
$spacing--normal: 0.75rem; // 12px
$spacing--big: 1rem; // 16px
$spacing--bigger: 1.25rem; //20px
$spacing--biggest: 1.5rem; // 24px

$border--radius: $spacing--small;

$block--padding: $spacing--big $spacing--biggest;

$border: 1px solid mix($color--background, $color--primary--lightest, 80);
$border--input: 1px solid $color--primary--lightest;
$border--divider: 1px solid mix($color--background, $color--primary--lightest, 20);

$font--normal: 500;
$font--bold: 800;

$font-size--smallest: 0.625rem;
$font-size--small: 0.75rem;
$font-size--normal: 0.8125rem; // 13px
$font-size--big: 0.9735rem;
$font-size--bigger: 1.125rem;
$font-size--bigest: 1.5rem;

$letter-spacing-big: 0.025rem;

$shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.03);
