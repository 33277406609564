@import '../../../scss/variables';
@import '../../../scss/mixins';

/*$nice-dates-color-gray-dark: #fff;
$nice-dates-color-gray: #fff;
$nice-dates-color-gray-light: #ddd;*/
$nice-dates-color-accent: $color--primary--normal;
/*$nice-dates-color-accent: #03BC8B;
$nice-dates-font-size-small: 12px;
$nice-dates-font-size-base: 14px;
$nice-dates-font-size-big: 16px;*/

@import 'react-nice-dates/src/style.scss';

.nice-dates-popover {
  max-width: 16rem;
  padding: 1rem;
}

.nice-dates-day_month {
  display: none;
}

.nice-dates-day.-outside {
  opacity: 0.5;
}

.nice-dates-day.-today {
  border: 1px solid $nice-dates-color-accent;
}

.field_input--date {
  @include terik-input;
}
.field_input--date:focus,
.field_input--date:active {
  border-color: $color--primary--normal;
}

.field_input--date::placeholder {
  color: $color--primary--light;
  font-size: $spacing--normal;
}

.field_input--date_divider {
  width: $spacing--small;
}

.date-range {
  display: flex;
  justify-content: space-between;
}
.date-range .field_input--date {
  width: 50%;
}
