@import '../../scss/variables';

.collapse {
  position: relative;

  .html-content {
    overflow: hidden;
    transition: height 300ms ease-in-out;
  }
}

.collapse--closed .html-content {
  height: $spacing--biggest * 3;
}

.collapse--open .html-content {
  height: auto;
}

.collapse-trigger {
  appearance: none;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  background-color: mix($color--background, $color--secondary--normal, 95);
  color: $color--secondary--normal;
  padding: $spacing--small;
  border-radius: $border--radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  margin-top: $spacing--big;
  width: $spacing--big * 2;
  height: $spacing--big * 2;
}

.collapse-trigger svg {
  stroke: $color--secondary--normal;
}

.collapse-trigger:hover,
.collapse-trigger:focus,
.collapse-trigger:active {
  background-color: mix($color--background, $color--secondary--normal, 70);
}

.collapse-trigger:hover  svg,
.collapse-trigger:focus  svg,
.collapse-trigger:active  svg {
  stroke: mix(#000, $color--secondary--normal, 50);
}

