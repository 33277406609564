@import '../../scss/variables';
@import '../../scss/mixins';

.dashboard-links {
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
}

.dashboard-link {
  flex-basis: 32%;
  margin-right: 2%;
  padding: $space--big $space--normal;
  border-radius: $border--radius;
  border: $border;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s, transform 0.15s;
  color: $color--text--normal;
  font-size: $font-size--bigest;
  background-color: #fff;
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    border-color: darken($color--card--border, 5%);
    transform: scale(1.02) translateY(-0.25rem);
    box-shadow: $shadow;
  }

  &:last-child {
    margin-right: 0;
  }
}

.dashboard-link svg {
  margin-right: $space--normal;
}
