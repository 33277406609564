@import '../../scss/variables';

.team__member {
  margin-bottom: 0.125rem;
  background-color: $row--background-color;
  border-radius: 0.125rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: auto 4rem 6rem 6rem 6rem;
  align-items: center;
  font-size: 0.75rem;
  height: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 0.5rem;

  .field_input,
  .true__control {
    background-color: $color--white;
  }
}
