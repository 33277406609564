@import '../../scss/variables';

.anonymous-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.anonymous-header .branding a {
  height: $spacing--big * 2;
  line-height: $spacing--big * 2;
  padding: $spacing--biggest;
  color: $color--primary--normal;
  font-size: $spacing--big;
  font-weight: $font--bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  display: block;
}

.anonymous-header .branding a:hover {
  color: $color--primary--darkest;
}

.anonymous-header .links {
  font-size: $spacing--big;
  font-weight: $font--normal;
  padding: $spacing--biggest;
}

.anonymous-header .links a {
  margin-left: $spacing--biggest;
  height: $spacing--big * 2;
  line-height: $spacing--big * 2;
  font-size: $spacing--big;
  text-decoration: none;
  color: $color--primary--normal;
  transition: color 0.2s ease-in-out;
}

.anonymous-header .links a:hover {
  color: $color--primary--darkest;
}
