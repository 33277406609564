@import '../../scss/variables';

/* TOOLBAR */
.toolbar {
  background: #fff;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  //width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: $border--divider;
}

.toolbar__top,
.toolbar__center,
.toolbar__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toolbar__top {
  justify-content: flex-start;
}

.toolbar__center {
  justify-content: center;
  align-items: center;
}

.toolbar__bottom {
  justify-content: flex-end;
}

.toolbar a {
  box-sizing: border-box;
  text-decoration: none;
  background-color: transparent;
  padding: $spacing--normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $spacing--biggest * 2;
  height: $spacing--biggest * 2;
}

.toolbar a svg,
.toolbar button svg {
  width: 2rem;
  height: 2rem;
}

// Normal.
.toolbar a svg *,
.toolbar button svg * {
  stroke: $color--primary--darkest;
  transition: stroke 0.25s;
}

// Hover.
.toolbar a:hover svg * {
  stroke: $color--secondary--normal;
}

// Active.
.toolbar a.active,
.toolbar button.active {
  box-shadow: 2px 0 0 0 $color--secondary--normal;
}

.toolbar a.active svg *,
.toolbar button.active svg * {
  stroke: $color--secondary--normal;
}

// Active hover.
.toolbar a.active:hover svg *,
.toolbar button.active:hover svg * {
  stroke: darken($color--secondary--normal, 20);
}

/*.toolbar .tab-dashboard svg * {
  fill: $color--primary--normal;
}

.toolbar .tab-dashboard:hover svg * {
  fill: $color--primary--normal;
}

.toolbar .tab-dashboard.active svg * {
  fill: darken($color--primary--darkest, 20);
}*/
