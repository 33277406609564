@import '../../scss/variables';

.page--main {
  padding: 0;
  border: 0;
}

.page--with-aside {
  display: flex;
}

.page--with-aside .page--main {
  flex-grow: 1;
}

.page--with-aside .page--aside {
  width: 24rem;
  min-height: 100vh;
  border-left: $border--divider;
  background-color: #fff;
}

.page--with-aside .page--aside .page--aside-content {
  position: sticky;
  top: 0;
}
