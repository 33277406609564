@import '../../scss/variables';

.list__summary {
  display: flex;
  margin-top: $spacing--small;
  font-size: $font-size--big;
  font-weight: $font--bold;
  color: $color--text--hilighted;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  padding: $spacing--small 0;
}

.list__summary--small {
  font-size: $font-size--normal;
  margin-top: $spacing--smallest;
  padding: $spacing--smallest 0 $spacing--small 0;
}
