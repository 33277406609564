@import '../../scss/variables';

.cell_group {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: $spacing--smallest $spacing--small;
}

.cell_group--info {
  flex-grow: 1;
}

.cell_group--quantity {
  width: 6rem;
}

.cell_group--prices {
  width: 16rem;
}
