@import '../../scss/variables';

.empty {
  padding: $spacing--big 0;
}

.empty__title {
  color: $color--text--normal;
  font-size: $font-size--big;
  font-weight: $font--bold;
}

.empty__description {
  color: $color--text--normal;
  font-size: $font-size--normal;
  line-height: 1.6;
  margin-top: $spacing--big;
}
