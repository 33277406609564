@import '../../scss/variables';
$badge-size: 2rem;

.user--badge {
  width: $badge-size;
  height: $badge-size;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color--primary--light;
  color: $color--primary--normal;
  font-size: 10px;
  font-weight: $font--bold;
}

.user--name {
  display: inline-flex;
  margin-left: $spacing--small;
}
