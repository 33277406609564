@import '../../../scss/variables';

.btn--edit {
  appearance: none;
  outline: 0;
  border: 0;
  position: absolute;
  top: $spacing--big ;
  right: $spacing--biggest;
  background-color: mix($color--background, $color--secondary--normal, 95);
  color: $color--secondary--normal;
  padding: $spacing--small;
  border-radius: $border--radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.btn--edit svg {
  stroke: $color--secondary--normal;
}

.btn--edit:hover,
.btn--edit:focus,
.btn--edit:active {
  background-color: mix($color--background, $color--secondary--normal, 70);
}

.btn--edit:hover  svg,
.btn--edit:focus  svg,
.btn--edit:active  svg {
  stroke: mix(#000, $color--secondary--normal, 50);
}
