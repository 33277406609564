@import '../../scss/variables';

.form__actions {
  display: flex;
  margin-top: $space--big;
}

.form__actions .button {
  line-height: $spacing--big * 3;
}
