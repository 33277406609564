@import '../../scss/variables';
@import '../../scss/mixins';

.row {
  border: $border;
  background-color: #fff;
  border-radius: $border--radius;
  font-size: $spacing--normal;
  color: $color--text--normal;
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
}

.row {
  margin-bottom: $spacing--thin;
}

.row:hover {
  outline: none;
  cursor: pointer;
}

.row__content {
  flex-grow: 1;
  @include for-tablet-portrait-up {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    cursor: pointer;
  }
}

.row:hover .row__actions {
  visibility: visible;
  transform: translateX(0);
}
