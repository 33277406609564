@import '../../scss/variables';

.section__actions {
  display: flex;
  justify-content: center;
  margin-top: $spacing--big;
}

.section__actions > * {
  margin-left: $spacing--small;
  margin-right: $spacing--small;
}
