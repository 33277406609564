@import '../../scss/variables';

.cell {
  line-height: 1.3rem;
}

.cell:not(:last-child) {
  margin-right: $spacing--big;
}

.cell--auto {
  flex-grow: 1;
}

.cell--right {
  text-align: right;
}

.cell .total {
  font-weight: $font--bold;
  display: block;
}

.cell--member,
.cell--supplier {
  width: 8rem;
}

.cell--price {
  width: 8rem;
}

.cell--day {
  width: 8rem;
}

.cell--quantity {
  width: 8rem;
}

.cell--state {
  width: 6rem;
}
.cell--role {
  width: 4rem;
}
