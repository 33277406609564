@import '../../../scss/variables';

.button--back {
  appearance: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  margin: 0 0 $spacing--big 0;
  font-size: $font-size--small;
  background-color: mix($color--background, $color--secondary--normal, 95);
  color: $color--secondary--normal;
  padding: $spacing--small;
  border-radius: $border--radius;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.button--back svg {
  stroke: $color--secondary--normal;
}

.button--back:hover,
.button--back:focus,
.button--back:active {
  background-color: mix($color--background, $color--secondary--normal, 70);
  color: mix(#000, $color--secondary--normal, 50);
}

.button--back:hover  svg,
.button--back:focus  svg,
.button--back:active  svg {
  stroke: mix(#000, $color--secondary--normal, 50);
}
