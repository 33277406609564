@import '../../scss/variables';

.parts {
  display: flex;
  margin-bottom: $spacing--biggest;
}

.page--aside .parts {
  flex-direction: column;
  margin-bottom: 0;
}

.page--aside .parts .part:not(:last-child) {
  margin-bottom: $spacing--big;
}
