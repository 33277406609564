@import '../../../scss/variables';
@import '../../../scss/mixins';

.field_input--text,
.field_input--price,
.field_input--hours,
.field_input--minutes {
  @include terik-input;
}

.field_input--text:focus,
.field_input--text:active,
.field_input--text:-webkit-autofill,
.field_input--text:autofill {
  border-color: $color--primary--normal;
}

.field_input_wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.field_input_prefix {
  margin-right: $spacing--smallest;
}

.field_input_suffix {
  margin-left: $spacing--smallest;
}

.field_input--wrapper {
  position: relative;
}
