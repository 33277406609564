@import 'variables';
@import 'mixins';

/* inter-500 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/inter-v3-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v3-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v3-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v3-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v3-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v3-latin-ext_latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-600 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter-v3-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v3-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v3-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v3-latin-ext_latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter-v3-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v3-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v3-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v3-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v3-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v3-latin-ext_latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-800 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/inter-v3-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v3-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v3-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v3-latin-ext_latin-800.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-900 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/inter-v3-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v3-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v3-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v3-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v3-latin-ext_latin-900.svg#Inter') format('svg'); /* Legacy iOS */
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;

  @media all and (min-width: 1280px) {
    font-size: 16px;
  }
  @media all and (min-width: 1600px) {
    font-size: 18px;
  }
}

body {
  margin: 0;
  padding: 0 0 0 $spacing--biggest * 2;
  color: $color--text--dark;
  background-color: #FCFDFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.anonymous {
  padding: 0;
}

* {
  font-family: $font--family;
  font-weight: $font--normal;
}


.page.enter {
  opacity: 0;
  z-index: 1;
  transform: translateX(-2rem);
}

.page.enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.page.enter-done {
  opacity: 1;
  transform: translateX(0);
}

.page.exit {
  opacity: 1;
  transform: translateX(0);
  z-index: 0;
}

.page.exit-active {
  opacity: 0;
  transform: translateX(2rem);
  transition: opacity 300ms, transform 300ms;
}
