@import '../../scss/variables';
@import '../../scss/mixins';

@supports (display: grid) {
  .list {
    @include grid;
    position: relative;
    box-sizing: border-box;
  }

  .list.list--row {
    grid-template-columns: 1fr;
    grid-row-gap: $spacing--thin;
  }
}

