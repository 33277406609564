@import 'variables';

.button--unstylled {
  appearance: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

@mixin terik-input {
  border: $border--input;
  outline: none;
  background: #fff;
  line-height: $spacing--big * 2;
  padding: $spacing--smallest $spacing--small;
  border-radius: $border--radius;
  box-sizing: border-box;
  width: 100%;
  font-size: $font-size--normal;
}

@mixin grid {
  display: grid;
  grid-column-gap: $spacing--biggest;
  grid-row-gap: $spacing--big;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
