@import '../../scss/variables';

.form--thin {
  max-width: 20rem;
}

.form--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0 $space--small;
  }
}

.form--centered {
  justify-content: center;
}

.form--thin--centered {
  max-width: 20rem;
  margin: 0 auto;
}

.form--bordered {
  border: 1px solid $color--gray-lightest;
  border-radius: 0.25rem;
  padding: $space--big;
}

.form--white {
  background-color: #fff;
}

.form__warning {
  border-top: 1px solid $color--divider;
  margin-top: $space--big;
  padding-top: $space--big;
  color: $color--alert;
  line-height: 1.5;
}

.form__instructions {
  margin-top: $space--big;
  font-size: $text--normal;
  color: #7890a1;
  line-height: 1.5;
}


.form__error {
  color: $color--error;
  margin: 0 0 $space 0;
  font-size: $text--big;
}

.form__loading{
  margin: 0 0 $space 0;
}
